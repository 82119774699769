<template>
  <div>
    <store-list ref="storeList" v-bind="{mode, options, items, loadScript}" :favorite.sync="favorite" @newScript="newScript" @reset="reset"></store-list>

    <store-view ref="storeView" v-bind="{mode, favorite}" :obj.sync="obj" @setBusy="setBusy"></store-view>

    <store-manage v-if="$R('DEV') && obj && mode !== 'view'" ref="storeManage" v-bind="{options, reset}" :obj.sync="obj"
                  @assignChartData="$refs.storeView.assignChartData"
                  @list="$refs.storeList.list"
                  @runScript="runScript"
                ></store-manage>
  </div>
</template>

<style>
</style>

<script>

import StoreList from './StoreList.vue'
import StoreView from './StoreView.vue'
import StoreManage from './StoreManage.vue'

export default {
  name: 'DataStore',
  title: 'Data Store',
  components: {
    StoreList,
    StoreView,
    StoreManage,
  },
  data() {
    return {
      mode: 'view',
      items: {list: [], filteredList: [], filteredListPrivate: [], page: [], pagePrivate: []},
      favorite: {},
      obj: null,
      options: {
        tags: [],
        roles: [],
        menus: [],
        users: [],
        libs: [],
      },
    }
  },
  async created() {
    // await this.list();

    this.loadRoles();
    this.loadUsers();
    if (this.$R('DEV')) {
      this.loadLibs();
    }
  },
  async mounted() {
    this.$refs.storeList.list().then(() => {
      this.mode = 'default';
      const no = this.$route.params.no;
      if (no) {
        const obj = this.items.list.find(e => e.no === +no);
        if (obj) {
          if (this.$route.query.mode === 'view') {
            this.mode = 'view';
          }
          this.loadScript(obj.no);
        }
      }
    });
  },
  methods: {
    async loadRoles() {
      const j = await this.$api.getJson(`/data/store/loadRoles`);
      if (j) {
        this.options.roles = j.roles;
      }
    },
    async loadUsers() {
      const j = await this.$api.getJson('/user/list?proj=id,name,teamId');
      if (j) {
        this.options.users = j.list.map(e => ({label: `${e.name}(${e.id})`, name: e.name, teamId: e.teamId, value: e.id}));
      }
    },
    async loadLibs() {
      const j = await this.$api.getJson('/data/store/libs/list?proj=no,id');
      if (j) {
        this.options.libs = j.list.map(e => ({label: `${e.no}. ${e.id}`, value: e.id}));
      }
    },
    newScript() {
      this.obj = {
        name: `[${this.$utils.kstD()}] ${this.$S.user.name} 님의 새 스크립트`,
        script: `const sheets = [];\n{\n  const rows = await my.godo.query('select 1 data');\n` +
          `  // const rows = await sdb.balis.deliver_boards.select('oid', {}, {limit: 10});\n` +
          `  // const rows = await sdb.hub.confirmed.select('goods_no', {}, {limit: 10});\n` +
          `  sheets.push({rows, name: 'Result'});\n` +
          `  // sheets.push({rows, keys: ['data'], name: 'Result', labelMap: {data: '데이터'}});\n}\n\nreturn {sheets};`,
        preScript: ``,
        scriptHeight: '500px',
        preScriptHeight: '100px',
        params: [],
        tags: [],
        roles: ['DEV'],
        users: [],
        menus: [],
        libs: [],
        charts: [],
        chartOnly: false,
        noAuthHide: true,
        downOnly: false,
      };
      setTimeout(() => { // setTimeout 이 없으면 storeView.resetScript 에서 obj 가 바로 변하지 않는다.
        this.$refs.storeView.resetScript();
      }, 0);
    },
    reset() {
      this.obj = null;
    },

    setBusy(key, value) {
      this.$refs.storeManage.setBusy(key, value);
    },
    runScript() {
      this.$refs.storeView.runScript();
    },
    async loadScript(no) {
      this.$refs.storeView.loadScript(no);
    },
  }
}
</script>
