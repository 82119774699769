<template>
  <b-modal title="Script Diff" v-model="modal" size="xl" ok-title="적용하기" cancel-title="닫기" @ok="$emit('applyScript', $refs.cm.codemirror.edit.getValue())">
    <codemirror class="maxHeight" ref="cm" :merge="true" :options="cmOption"></codemirror>
    <div class="text-right mt-2">
      <b-btn class="ml-1 mb-1" size="sm" @click="setHistory(h)" v-for="h in history" :key="h.name">{{h.name}}</b-btn>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ScriptDiffModal',
  data() {
    return {
      left: '',
      right: '',
      leftIdx: 0,
      rightIdx: 0,
      history: [],
      modal: false,
      cmOption: {
        value: '',
        origLeft: null,
        orig: '',
        connect: 'align',
        mode: 'text/javascript',
        theme: 'default',
        collapseIdentical: false,
        highlightDifferences: true
      }
    };
  },
  created() {
    // getDiff();
  },
  methods: {
    showModal(coll, no, script) {
      this.cmOption.value = script;
      // this.cmOption.orig = script;
      this.modal = true;
      setTimeout(() => {
        this.$refs.cm.codemirror.wrap.style.height = 'calc(100vh - 250px)';
        this.$refs.cm.codemirror.edit.setSize(null, 'calc(100vh - 250px)');
        this.$refs.cm.codemirror.rightOriginal().setSize(null, 'calc(100vh - 250px)');
        this.$refs.cm.refresh();
      }, 0);
      this.list(no);
    },
    async list(no) {
      const j = await this.$api.getJson(`/data/store/loadDiff?no=${no}`);
      if (j) {
        this.history = j.history.reverse();
        // this.cmOption.orig = j.history[0].script;
        this.$refs.cm.codemirror.rightOriginal().setValue(j.history[0].script);
        // setTimeout(() => {
        //   this.$refs.cm.codemirror.edit.refresh();
        //   this.$refs.cm.codemirror.rightOriginal().refresh();
        //   console.log(this.$refs.cm);
        // }, 100);
      }
    },
    setHistory(h) {
      // this.cmOption.orig = h.script;
      this.$refs.cm.codemirror.rightOriginal().setValue(h.script);
    }
  },
}
</script>

<style scoped>
.maxHeight { height: calc(100vh - 250px); }
</style>
