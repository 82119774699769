<template>
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12" md="4">
          <small>이름</small>
          <b-input v-model="obj.name"></b-input>
        </b-col>
        <b-col cols="6" md="4">
          <small>태그</small>
          <v-select v-model="obj.tags" :options="options.tags" multiple taggable push-tags placeholder="상품, 주문 등의 TAG 를 입력해주세요">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">관련 TAG 를 입력해주세요.</em>
            </template>
          </v-select>
        </b-col>
        <b-col cols="6" md="4">
          <small>메뉴</small>
          <v-select v-model="obj.menus" :options="options.menus" multiple taggable push-tags placeholder="메뉴 경로를 순서에 맞게 입력해주세요">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">메뉴 경로를 입력해주세요.</em>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12" md="4">
          <small>권한(하나 이상 일치하면 실행가능, 사용자와 AND 관계)</small>
          <v-select v-model="obj.roles" :options="options.roles" multiple placeholder="이 스크립트를 실행할 수 있는 권한들을 입력해주세요">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">존재하는 권한을 입력해주세요.</em>
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="4">
          <small>실행가능 사용자(권한과 AND 관계)</small>
          <v-select v-model="obj.users" :options="options.users" multiple placeholder="이 스크립트를 실행할 수 있는 사용자들을 입력해주세요">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">존재하는 사용자를 입력해주세요.</em>
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="4">
          <small>사용할 Libs</small>
          <v-select v-model="obj.libs" :options="options.libs" multiple index="value" placeholder="이 스크립트에 포함시킬 라이브러리를 선택해주세요">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">존재하는 Library를 입력해주세요.</em>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="6" md="2">
          <small>권한 설정</small>
          <b-form-checkbox class="col-form-label" v-model="obj.noAuthHide">실행권한이 없을 때 숨기기</b-form-checkbox>
        </b-col>
        <b-col cols="6" md="2">
          <small>View Mode 설정</small>
          <b-form-checkbox class="col-form-label" v-model="obj.chartOnly">Chart 만 보여주기</b-form-checkbox>
        </b-col>
        <b-col cols="6" md="2">
          <small>Run Mode 설정</small>
          <b-form-checkbox class="col-form-label" v-model="obj.downOnly">Down 만 가능하도록</b-form-checkbox>
        </b-col>
        <b-col cols="6" md="2">
          <small>개인정보 여부</small>
          <b-form-checkbox class="col-form-label" v-model="obj.private">개인정보가 포함됨</b-form-checkbox>
        </b-col>
        <b-col cols="6" md="4">
          <small>CRON(주기적 자동실행, 비워두면 비활성화)</small>

          <b-input-group>
            <b-input v-model="obj.cron" placeholder="ex) 0 */2 * * *"></b-input>
            <b-input-group-append v-if="obj.cron">
              <b-button variant="primary" @click="$utils.open(`https://crontab.guru/#${obj.cron.replace(/ /g, '_')}`)">
                <i class="fa fa-external-link"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!--      <b-tabs v-model="obj.tabIndex">
              <b-tab title="쿼리">
                <b-form class="mb-2" inline>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text>
                        Table
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input style="width: 300px" v-model="obj.target" placeholder="mysql.godo, mongo.sdb.hub.meta_shops 등 테이블 경로"></b-input>
                  </b-input-group>
                </b-form>
                <prism-editor class="my-editor" v-model="obj.query" :highlight="highlighter" line-numbers></prism-editor>
              </b-tab>
              <b-tab title="스크립트">
                <prism-editor class="my-editor" v-model="obj.script" :highlight="highlighter" line-numbers></prism-editor>
              </b-tab>
            </b-tabs>-->
      <div class="clearfix">
        <span class="pull-right">
          height:
          <b-badge v-for="h in ['300px', '600px', '800px', '80vh']" class="pointer" :variant="obj.scriptHeight === h ? 'success' : 'light'"
                   @click="setHeight('script', h)" :key="h">{{h.replace('px', '').replace('vh', '%')}}</b-badge>
        </span>
        <small>스크립트 <i v-if="scriptError" class="fa fa-exclamation text-danger" v-b-tooltip="scriptError"></i></small>
      </div>

      <!--      <prism-editor class="my-editor" v-model="obj.script" :highlight="highlighter" :style="{maxHeight: scriptHeight}" @input="checkScriptEval('script')"
                          line-numbers></prism-editor>-->
      <codemirror ref="script" v-model="obj.script" @input="checkScriptEval('script')"></codemirror>

      <div class="clearfix my-2">
        <div class="pull-left">
          <b-btn class="mr-1" variant="success" @click="runScript" :disabled="busy.run">실행<b-spinner class="ml-1" small v-if="busy.run"></b-spinner></b-btn>
          <b-btn class="mr-1" variant="primary" @click="save" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
        </div>
        <div class="pull-right">
          <b-dropdown class="mr-1" ref="dropdown" dropup right variant="light" no-caret>
            <template v-slot:button-content>
              Snippet
            </template>
            <b-dropdown-form class="mt-2 mb-2" style="width: 800px">
              <pre v-text="preSnippet" style="height: 150px; white-space: break-spaces; overflow-y: auto"></pre>
              <b-row>
                <b-col v-for="s in snippet" :key="s.title">
                  <small>{{s.title}}</small><br/>
                  <template v-for="e in s.list">
                    <b-badge class="pointer mr-1 mb-1" @mouseover="showSnippet(e.data)" @click="copySnippet(e.data)"
                             :key="e.name">
                      {{ e.name }}
                    </b-badge>
                  </template>
                </b-col>
              </b-row>
            </b-dropdown-form>
          </b-dropdown>
          <b-btn v-if="obj.no" variant="light" @click="$refs.diffModal.showModal('store', obj.no, obj.script);">Diff</b-btn>
        </div>
      </div>

      <div><small>파라미터</small></div>
      <div class="d-flex flex-row flex-wrap">
        <draggable class="" v-model="obj.params" handle=".handle">
          <div v-for="(p, idx) in obj.params" :key="idx">
            <b-form class="d-flex align-items-center position-relative mr-3 mb-1">
              <div class="flex-grow-0 w-65px d-flex align-items-center justify-content-center handle" style="cursor: pointer">
                <i class="fa fa-bars"></i>
                <span class="ml-2 fs-15">{{idx+1}}</span>
              </div>
              <div class="w-150px">
                <div><small>타입</small></div>
                <b-form-select v-model="p.type" :options="[
                  {text: 'String', value: 'string'},
                  {text: 'Password', value: 'password'},
                  {text: 'Number', value: 'number'},
                  {text: 'Select', value: 'select'},
                  {text: 'Checkbox', value: 'check'},
                  {text: 'Radio', value: 'radio'},
                  /*{text: 'Date', value: 'date'},*/
                  {text: 'Text', value: 'text'},
                  {text: 'JSON', value: 'json'},
                  {text: 'Shop 프리셋', value: 'shop_preset'},
                  {text: 'Brand 프리셋', value: 'brand_preset'},
                  {text: 'Category 프리셋', value: 'category_preset'},
                  {text: 'DateFromTo', value: 'date_from_to'},
                ]" :disabled="p.dynamic">
                </b-form-select>
              </div>
              <template v-if="p.type === 'date_from_to'">
                <div class="flex-grow-1">
                  <div><small>한글명</small></div>
                  <b-input v-model="p.name" :disabled="p.dynamic"></b-input>
                </div>
                <div class="flex-grow-1">
                  <div><small>시작일 파라미터명</small></div>
                  <b-input v-model="p.from" :disabled="p.dynamic"></b-input>
                </div>
                <div class="flex-grow-1">
                  <div><small>종료일 파라미터명</small></div>
                  <b-input v-model="p.to" :disabled="p.dynamic"></b-input>
                </div>
                <div class="flex-grow-1">
                  <div><small>기본값</small></div>
                  <b-input v-model="p.init" placeholder="ex) 1 week" :disabled="p.dynamic"></b-input>
                </div>
              </template>
              <div v-else class="flex-grow-1">
                <div><small>파라미터명</small></div>
                <b-input v-model="p.name" :disabled="p.dynamic"></b-input>
              </div>
              <template v-if="['string', 'password', 'number', 'select', 'check', 'radio', 'date', 'text', 'json'].includes(p.type)">
                <div v-if="['string', 'password', 'number', 'select', 'check', 'radio', 'date', 'text', 'json'].includes(p.type)" class="flex-grow-1">
                  <div><small>한글명</small></div>
                  <b-input v-model="p.desc" :disabled="p.dynamic"></b-input>
                </div>
                <div v-if="['string', 'password', 'number', 'select', 'check', 'radio', 'date', 'text', 'json'].includes(p.type)" class="flex-grow-1">
                  <div><small>기본값</small></div>
                  <b-input v-model="p.default" :type="p.type === 'password' ? `password` : 'text'" :disabled="p.dynamic"></b-input>
                </div>
                <div v-if="['string', 'password', 'number', 'select', 'check', 'radio', 'date', 'text', 'json'].includes(p.type)" class="flex-grow-1">
                  <div><small>Placeholder<i class="fa fa-question-circle ml-1" v-b-tooltip="'배경에 존재하는 placeholder 이자 마우스 오버하면 보일 예시값 혹은 설명'"></i></small></div>
                  <b-input v-model="p.placeholder" :disabled="p.dynamic"></b-input>
                </div>
                <div v-if="['select', 'check', 'radio'].includes(p.type)" class="flex-grow-1">
                  <div><small>Option 값(콤마로 구분 or JSON)</small></div>
                  <b-input v-model="p.options" placeholder="ex) A,B,C or [{text, value}, ...]" :disabled="p.dynamic"></b-input>
                </div>
                <div v-if="['string', 'password', 'number', 'select', 'check', 'radio', 'date', 'text', 'json'].includes(p.type)" class="w-90px">
                  <div><small>필수여부</small></div>
                  <b-form-select v-model="p.required" :options="[
                    {text: '필수', value: true},
                    {text: '선택', value: false},
                  ]" :disabled="p.dynamic"></b-form-select>
                </div>
              </template>
              <div v-if="p.dynamic" class="text-center" style="width:35px">
                <div><small>설명</small></div>
                <b-btn variant="outline-secondary" class="w-100" title="프리 스크립트에 의해 dynamic 하게 생성된 파라미터입니다" disabled>
                  <i class="fa fa-question"></i>
                </b-btn>
              </div>
              <div v-else class="text-center" style="width:35px">
                <div><small>기능</small></div>
                <b-dropdown ref="dropdown" right variant="outline-dark" class="">
                  <b-dropdown-form class="mt-2 mb-2" style="white-space: nowrap">
                    <b-btn variant="success" class="mr-2" @click="copyParam(p)">복사</b-btn>
                    <b-btn variant="danger" @click="obj.params.splice(idx, 1)">삭제</b-btn>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <!--              <div class="flex-grow-0 w-65px d-flex align-items-center justify-content-center handle" style="cursor: pointer" @click="obj.params.splice(idx, 1)">
                              <i class="fa fa-close text-danger"></i>
                            </div>-->
            </b-form>
          </div>
        </draggable>
      </div>
      <div class="pointer text-secondary d-flex align-items-center justify-content-center my-2"
           style="width:580px; height:50px; border-radius: 6px; border: dashed 1px #ccc;" @click="addParams">
        <i class="fa fa-plus fa-2x"></i>
      </div>

      <div><small>차트</small></div>
      <div class="">
        <draggable class="" v-model="obj.charts" handle=".handle">
          <div v-for="(c, idx) in obj.charts" :key="idx" class="mb-1">
            <div class="d-flex align-items-center position-relative mr-3">
              <div class="flex-grow-0 w-65px d-flex align-items-center justify-content-center handle" style="cursor: pointer">
                <i class="fa fa-bars"></i>
                <span class="ml-2 fs-15">{{idx+1}}</span>
              </div>
              <div class="w-150px">
                <div><small>차트명</small></div>
                <b-input v-model="c.name" placeholder="ex) 상품수"></b-input>
              </div>
              <div class="w-90px">
                <div><small>데이터 원천<i class="fa fa-question-circle ml-1" v-b-tooltip="'시트 번호(0부터 시작) 또는 시트명 입력, 생략시 첫 번째 시트 데이터 사용'"></i></small></div>
                <b-input v-model="c.sheetIdx" placeholder="ex) 0"></b-input>
              </div>
              <div class="w-150px">
                <div><small>데이터 필터링<i class="fa fa-question-circle ml-1" v-b-tooltip="'rows 를 사용하여 filter 를 하는 함수를 입력합니다'"></i></small></div>
                <b-input v-model="c.filter" placeholder="ex) rows.filter(row => row.point == 5)"></b-input>
              </div>
              <div class="w-125px">
                <div><small>타입</small></div>
                <b-form-select v-model="c.type" :options="[
                  {text: 'Line', value: 'line'},
                  {text: 'Bar', value: 'bar'},
                  {text: 'Radar', value: 'radar'},
                  {text: 'Pie', value: 'pie'},
                  {text: 'Doughnut', value: 'doughnut'},
                  {text: 'PolarArea', value: 'polar'},
                  {text: 'Value', value: 'value'},
                ]">
                </b-form-select>
              </div>
              <div class="flex-grow-1" v-if="!['value'].includes(c.type)">
                <div><small>Series 지정</small></div>
                <b-form-select v-model="c.series" :options="[
                  {text: '행(데이터가 행으로 증가)', value: 'row'},
                  {text: '열(데이터가 열의 컬럼에 존재)', value: 'col'},
                  {text: 'Script 로 지정', value: 'script'},
                ]"></b-form-select>
              </div>

              <!-- line, bar, radar chart -->
              <template v-if="['line', 'bar', 'radar'].includes(c.type) && c.series !== 'script'">
                <div class="w-125px">
                  <div><small>Series Label 컬럼</small></div>
                  <b-input v-model="c.labelCol" placeholder="ex) day"></b-input>
                </div>

                <template v-if="c.series === 'row'">
                  <div class="w-300px">
                    <div><small>Series 컬럼 선택 <i class="fa fa-question-circle ml-1" v-b-tooltip="'cnt:A:bar,sum:B:line 등 축과 타입 선택 가능'"></i></small></div>
                    <b-input v-model="c.seriesCols" placeholder="ex) A,B,C"></b-input>
                  </div>
                </template>
                <template v-else-if="c.series === 'col'">
                  <div class="flex-grow-1">
                    <div><small>데이터 컬럼 선택</small></div>
                    <b-form-select v-model="c.dataCols" :options="[
                      {text: '전체', value: 'all'},
                      {text: '일부선택', value: 'include'},
                      {text: '일부제외', value: 'exclude'},
                    ]"></b-form-select>
                  </div>
                  <div class="w-300px" v-if="c.dataCols === 'include'">
                    <div><small>선택할 컬럼</small></div>
                    <b-input v-model="c.includeCols" placeholder="ex) A,B,C"></b-input>
                  </div>
                  <div class="flex-grow-1" v-if="c.dataCols === 'exclude'">
                    <div><small>제외할 컬럼</small></div>
                    <b-input v-model="c.excludeCols" placeholder="ex) A,B,C"></b-input>
                  </div>
                </template>
              </template>
              <!-- line, bar, radar chart end -->

              <!-- pie, doughnut, polar chart -->
              <template v-if="['pie', 'doughnut', 'polar'].includes(c.type) && c.series !== 'script'">
                <template v-if="c.series === 'row'">
                  <div class="w-125px">
                    <div><small>Label 컬럼 선택</small></div>
                    <b-input v-model="c.labelCol" placeholder="ex) date"></b-input>
                  </div>
                  <div class="w-125px">
                    <div><small>데이터 컬럼 선택</small></div>
                    <b-input v-model="c.dataCol" placeholder="ex) cnt"></b-input>
                  </div>
                </template>
                <template v-else-if="c.series === 'col'">
                  <div class="flex-grow-1">
                    <div><small>데이터 컬럼 선택</small></div>
                    <b-form-select v-model="c.dataCols" :options="[
                      {text: '전체', value: 'all'},
                      {text: '일부선택', value: 'include'},
                      {text: '일부제외', value: 'exclude'},
                    ]"></b-form-select>
                  </div>
                  <div class="w-300px" v-if="c.dataCols === 'include'">
                    <div><small>선택할 컬럼</small></div>
                    <b-input v-model="c.includeCols" placeholder="ex) A,B,C"></b-input>
                  </div>
                  <div class="flex-grow-1" v-if="c.dataCols === 'exclude'">
                    <div><small>제외할 컬럼</small></div>
                    <b-input v-model="c.excludeCols" placeholder="ex) A,B,C"></b-input>
                  </div>
                </template>
              </template>
              <!-- pie, doughnut, polar chart end -->

              <!-- value -->
              <div class="flex-grow-1" v-if="['value'].includes(c.type)">
                <div><small>데이터 선택<i class="fa fa-question-circle ml-1" v-b-tooltip="'rows 를 사용하여 보여줄 값을 반환하는 함수를 입력합니다'"></i></small></div>
                <b-input v-model="c.selector" placeholder="ex) rows[0].total"></b-input>
              </div>
              <!-- value end -->

              <!-- 차트 속성 -->
              <div v-if="c.series === 'script'" class="text-center" style="width:35px">
                <div><small>설명</small></div>
                <b-btn variant="outline-secondary" class="w-100" v-b-modal.chartScriptModal>
                  <i class="fa fa-question"></i>
                </b-btn>
                <b-modal id="chartScriptModal" title="Chart 스크립트 설명" size="lg" ok-only>
                  * script 에서는 $this, $this.obj = {params, charts, sheets, ...}, rows, c = {name, data, options} 를 쓸 수 있습니다<br/>
                  * options 에는 name 이 있을 경우 세팅되어 있습니다<br/>
                  * script 를 이용해서 c 객체에 data = {labels: [], datasets: []}, options = {...} 를 구성해야 합니다.<br/>
                  * example
                  <pre class="bg-light p-2">c.data.labels = ['A', 'B', 'C'];
c.data.datasets = [{
  label: 'Serise A',
  data: [1,2,3],
  borderWidth: 2,
  fill: false,
}];
c.options.name = '커스텀 차트';</pre>
                </b-modal>
              </div>
              <div v-else class="text-center" style="width:35px">
                <div><small>속성</small></div>
                <b-dropdown text="" ref="dropdown" right variant="outline-success" class="">
                  <b-dropdown-form class="mb-2">
                    <template v-if="['line', 'bar'].includes(c.type)">
                      <div><small>ZeroBase</small></div>
                      <div class="col-form-label">
                        <b-checkbox v-model="c.opts.zero"></b-checkbox>
                      </div>
                    </template>
                    <template v-if="c.type === 'bar'">
                      <div><small>Stack</small></div>
                      <div class="col-form-label">
                        <b-checkbox v-model="c.opts.stack"></b-checkbox>
                      </div>
                    </template>
                    <template v-if="c.type === 'line'">
                      <div><small>Point Radius</small></div>
                      <b-input v-model="c.opts.radius" placeholder="3"></b-input>
                      <div><small>X Axis is Time</small></div>
                      <b-checkbox v-model="c.opts.xIsTime"></b-checkbox>
                    </template>
                    <template v-if="['pie', 'doughnut', 'polarArea'].includes(c.type)">
                      <div><small>정렬</small></div>
                      <b-form-radio-group class="col-form-label" v-model="c.opts.sort" :options="[
                        {text: '없음', value: ''},
                        {text: '큰순서', value: 'desc'},
                        {text: '작은순서', value: 'asc'},
                      ]"></b-form-radio-group>
                    </template>
                    <template v-if="!['value'].includes(c.type)">
                      <div><small>DataLabels</small></div>
                      <b-checkbox v-model="c.opts.datalabels"></b-checkbox>
                    </template>
                  </b-dropdown-form>
                </b-dropdown>
              </div>

              <div class="text-center" style="width:35px">
                <div><small>스타일</small></div>
                <b-dropdown ref="dropdown" right variant="outline-primary" class="">
                  <b-dropdown-form class="mb-2">
                    <div class="w-200px">
                      <div><small>style <i class="fa fa-question-circle ml-1" v-b-tooltip="'chart 에 적용될 style'"></i></small></div>
                      <b-input v-model="c.style" placeholder="max-height: 300px"></b-input>
                    </div>
                    <div class="w-200px">
                      <div><small>class <i class="fa fa-question-circle ml-1" v-b-tooltip="'chart col 에 적용될 class'"></i></small></div>
                      <b-input v-model="c.class" placeholder="col-6"></b-input>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>

              <div class="text-center" style="width:35px">
                <div><small>기능</small></div>
                <b-dropdown ref="dropdown" right variant="outline-dark" class="">
                  <b-dropdown-form class="mt-2 mb-2" style="white-space: nowrap">
                    <b-btn variant="success" class="mr-2" @click="copyChart(c)">복사</b-btn>
                    <b-btn variant="danger" @click="obj.charts.splice(idx, 1)">삭제</b-btn>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </div>

            <!-- script -->
            <div class="" style="margin-left: 65px;" v-if="!['value'].includes(c.type) && c.series === 'script'">
              <!--              <prism-editor class="my-editor" v-model="c.script" :highlight="highlighter" :style="{maxHeight: '400px'}"
                                          line-numbers></prism-editor>-->
              <codemirror v-model="c.script"></codemirror>
            </div>
            <!-- script end -->
          </div>
        </draggable>
      </div>
      <div class="pointer text-secondary d-flex align-items-center justify-content-center my-2"
           style="width:580px; height:50px; border-radius: 6px; border: dashed 1px #ccc;" @click="addChart">
        <i class="fa fa-plus fa-2x"></i>
      </div>

      <b-row>
        <b-col cols="12" md="12">
          <small>설명(사용자가 볼 설명, html 사용가능, 엔터는 &lt;br&gt;로 치환)</small>
          <b-textarea rows="5" v-model="obj.alert"></b-textarea>
        </b-col>
        <!--        <b-col cols="12" md="2">
                  <small>CRON(주기적 자동실행, 비워두면 비활성화)</small>
                  <b-input v-model="obj.cron" placeholder="ex) 0 */2 * * *"></b-input>
                </b-col>-->
      </b-row>

      <div>
        <small>비고(개발자가 참고할 내용)</small>
        <b-textarea rows="5" v-model="obj.desc"></b-textarea>
      </div>

      <div class="clearfix">
        <span class="pull-right">
          height:
          <b-badge v-for="h in ['100px', '300px', '600px', '800px', '80vh']" class="pointer" :variant="obj.preScriptHeight === h ? 'success' : 'light'"
                   @click="setHeight('preScript', h)" :key="h">{{h.replace('px', '').replace('vh', '%')}}</b-badge>
        </span>
        <small>프리 스크립트 <i v-if="preScriptError" class="fa fa-exclamation text-danger" v-b-tooltip="preScriptError"></i>
          - params, charts 등을 dynamic 하게 설정할 때 사용합니다
          <i class="fa fa-question-circle" v-b-tooltip="`obj 를 파라미터로 받아서 변경합니다. 저장한 뒤부터 사용 가능합니다. 클라이언트 사이드에서 실행됩니다.`"></i>
          sample :
          <i class="fa fa-copy pointer" :title="preScriptSample" @click="$utils.copyAlert(preScriptSample)" ></i>
        </small>
        <!-- ex) // obj.params.push({name: 'test', type: 'string', desc: '', default: '', required: false}) -->
      </div>
      <!--      <prism-editor class="my-editor" v-model="obj.preScript" :highlight="highlighter" :style="{maxHeight: preScriptHeight}" @input="checkScriptEval('preScript')"
                          line-numbers></prism-editor>-->
      <codemirror ref="preScript" v-model="obj.preScript" @input="checkScriptEval('preScript')" @ready="e => e.setSize(null, this.obj.preScriptHeight)"></codemirror>

      <hr/>
      <b-btn class="mr-1" variant="primary" @click="save" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
      <b-btn class="mr-1" variant="danger" @click="remove" :disabled="busy.remove">삭제<b-spinner class="ml-1" small v-if="busy.remove"></b-spinner></b-btn>
      <b-btn class="mr-1" variant="light" @click="$modal.show({title: 'JSON 보기', item: obj, type: 'json'})">JSON</b-btn>
    </b-card>

    <diff-modal ref="diffModal" @applyScript="applyScript"></diff-modal>
  </div>
</template>

<style scoped>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #fafafa;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  border: 1px solid #ccc;
  max-height: 400px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

<script>
// import Prism Editor
import {PrismEditor} from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import {highlight, languages} from 'prismjs/components/prism-core';

import StoreMixin from '@/views/data/StoreMixin'
import DiffModal from '@/views/data/ScriptDiffModal.vue'
import snippet from '@/views/data/StoreSnippet'

export default {
  name: 'StoreManage',
  props: ['options', 'obj', 'reset'],
  mixins: [
    StoreMixin,
  ],
  components: {
    DiffModal,
    PrismEditor,
  },
  data() {
    return {
      mode: 'view',
      busy: {save: false, remove: false, run: false},
      scriptError: '',
      preScriptError: '',
      preScriptSample: `obj.params.unshift({dynamic: true, type: 'string', name: 'st', desc: '시작일자', ` +
        `default: $this.$moment().add(-1, 'day').format('YYYY-MM-DD'), placeholder: 'YYYY-MM-DD', required: true});`,
      snippet,
      preSnippet: '',
    }
  },
  created() {
  },
  mounted() {
    this.$refs.script.codemirror.setSize(null, this.obj.scriptHeight || (this.obj.scriptHeight = '300px'));
    this.$refs.preScript.codemirror.setSize(null, this.obj.preScriptHeight || (this.obj.preScriptHeight = '100px'));
  },
  watch: {
    obj: {
      deep: true,
      handler(tobe, asis) {
        if (tobe.scriptHeight !== asis.scriptHeight) {
          this.$refs.script.codemirror.setSize(null, this.obj.scriptHeight || (this.obj.scriptHeight = '300px'));
        }
        if (tobe.preScriptHeight !== asis.preScriptHeight) {
          this.$refs.preScript.codemirror.setSize(null, this.obj.preScriptHeight || (this.obj.preScriptHeight = '100px'));
        }
      },
    },
    // scriptHeight(v) {
    //   this.$refs.script.codemirror.setSize(null, v);
    // },
    // preScriptHeight(v) {
    //   this.$refs.preScript.codemirror.setSize(null, v);
    // }
  },
  methods: {
    checkScriptEval(target) {
      try {
        if (!this.obj[target].match(/^\s*select /)) { // 쿼리가 아닐 때만 eval
          eval(`(async function f() {${this.obj[target]}})`);
          // new Function(`(async function f() {${this.obj[target]}})`); // 이렇게 해도 error line 을 찾기 어렵다
        }
      } catch (e) {
        this[target + 'Error'] = e.toString();
        // console.error(e.stack);
        return false;
      }
      this[target + 'Error'] = '';
      return true;
    },
    setHeight(target, height) {
      this.$refs[target].codemirror.setSize(null, height);
      this.obj[`${target}Height`] = height;
      this.$forceUpdate();
    },
    addParams() {
      this.obj.params = this.obj.params || [];
      this.obj.params.push({name: '', type: 'string', desc: '', default: '', required: false});
    },
    copyParam(p) {
      const newParam = this.$utils.clone(p);
      newParam.name += '_c';
      this.obj.params.push(newParam);
    },
    addChart() {
      this.obj.charts = this.obj.charts || [];
      this.obj.charts.push({name: '', type: 'line', series: 'row', labelCol: '', seriesCols: '',
        data: {labels: ['1'], datasets: [{
            label: '데이터',
            // backgroundColor: '#f87979',
            data: [0],
            fill: false,
          }]},
        opts: {},
      });
      this.$forceUpdate();
    },
    copyChart(chart) {
      const newChart = [chart].map(this.chartCloneLambda)[0];
      this.obj.charts.push(newChart);
      this.$forceUpdate();
    },
    validateCharts() {
      for (const {type, series, labelCol, seriesCols, dataCols, script} of this.obj.charts) {
        if (!['value'].includes(type) && series === 'script') {
          try {
            eval(`(async function f() {${script}})`);
          } catch (e) {
            return alert(`Chart 스크립트에서 에러가 발생했습니다: ${e.message}`);
          }
        } else if (['line', 'bar', 'radar'].includes(type)) {
          if (!labelCol) {
            return alert('Series Label 컬럼을 입력해주세요');
          }
          if (series === 'row' && !seriesCols) {
            return alert('Series 컬럼을 입력해주세요');
          }
          if (series === 'col' && !dataCols) {
            return alert('데이터 컬럼을 선택해주세요');
          }
        }
      }
      return true;
    },
    runScript() {
      this.$emit('runScript');
    },
    setBusy(key, value) {
      this.busy[key] = value;
    },
    async save() {
      if (this.scriptError) return alert(`script 에러를 확인해주세요: ${this.scriptError}`);
      if (this.preScriptError) return alert(`preScript 에러를 확인해주세요: ${this.preScriptError}`);

      const params = this.obj.params.filter(e => !e.dynamic);
      const emptyNameParams = params.find(e => !e.name);
      if (emptyNameParams) {
        return alert(emptyNameParams.type + ' 타입 파라미터의 파라미터명을 설정해주세요');
      }
      const emptyOptionSelectParams = params.find(e => e.type === 'select' && !e.options);
      if (emptyOptionSelectParams) {
        return alert(emptyOptionSelectParams.name + ' 파라미터의 옵션들을 설정해주세요');
      }

      const obj = {...this.obj, params};
      if (this.obj.charts.length) {
        if (!this.validateCharts()) return;
        const charts = this.obj.charts.filter(e => !e.dynamic).map(this.chartCloneLambda);
        obj.charts = charts;
        this.$emit('assignChartData'); // await this.assignChartData();
      }
      if (obj.cron && obj.cron.split(' ').length < 5) return alert(`cron 은 5개 이상의 token 으로 구성되어야 합니다`);

      this.busy.save = true;
      const j = await this.$api.postJson('/data/store/save', {obj});
      this.busy.save = false;
      if (j) {
        this.obj.no = j.no;
        this.$alertTop('저장되었습니다');
        this.$emit('list'); // this.list();
        this.$forceUpdate();
      }
      return true;
    },
    async remove() {
      if (!confirm('정말로 스크립트를 삭제하시겠습니까?')) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/data/store/remove', {no: this.obj.no});
      this.busy.remove = false;
      if (j) {
        this.$alertTop('삭제되었습니다', {variants: 'danger'});
        this.reset();
      }
    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    applyScript(s) {
      this.obj.script = s;
    },
    showSnippet(data) {
      this.preSnippet = data.trim();
    },
    copySnippet(data) {
      this.$utils.copyAlert(data.trim());
      this.preSnippet = '';
    }
  }
}
</script>
