<template>
  <div v-if="mode !== 'view'">
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="" v-model="form.list.search" @keypress.enter="list()" autocomplete="off" v-focus></b-form-input>
        <b-input-group-append>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <b-collapse id="collapseList" v-model="collapse.list">
        <b-form class="mb-2" v-if="$R('DEV')" inline>
          <b-form-checkbox v-model="form.list.searchSrc" class="mr-3">소스코드를 검색에 포함</b-form-checkbox>
          <b-form-checkbox v-model="form.list.hasParams" class="mr-3">파라미터 있음</b-form-checkbox>
          <b-form-checkbox v-model="form.list.hasCharts" class="mr-3">차트 있음</b-form-checkbox>
          <b-form-checkbox v-model="form.list.hasMenus" class="mr-3">메뉴 있음</b-form-checkbox>
          <b-form-checkbox v-model="form.list.hasTags" class="mr-3">태그 있음</b-form-checkbox>
          <b-form-checkbox v-model="form.list.hasCron" class="mr-3">Cron 있음</b-form-checkbox>
          <b-form-checkbox v-model="form.list.hasPreScript" class="mr-3">프리 스크립트 있음</b-form-checkbox>
          설명 : &nbsp;
          <b-form-radio-group v-model="form.list.hasAlert"
                              :options="[{text: '전체', value: 'ALL'}, {text: '있음', value: 'y'}, {text: '없음', value: 'n'}]"></b-form-radio-group>
          비고 : &nbsp;
          <b-form-radio-group v-model="form.list.hasDesc"
                              :options="[{text: '전체', value: 'ALL'}, {text: '있음', value: 'y'}, {text: '없음', value: 'n'}]"></b-form-radio-group>
        </b-form>

        <!--      <div v-if="options.tags.length">
                <b-btn variant="outline-dark" size="sm" class="pointer mr-1 mb-1" v-for="t in options.tags" :key="t" @click="() => { form.list.search = t; list(); }">{{t}} ({{tagCntMap[t]}})</b-btn>
              </div>-->


        <template>
          <template v-if="this.items.list.some(e => e.noAuthHide)">
            <b-tabs>
              <b-tab title="Public">
                <div class="text-center" v-if="busy.list">
                  <b-spinner></b-spinner>
                </div>
                <store-list-page v-else ref="storeListPage" :pageLimit.sync="pageLimit" :currentPage.sync="currentPage"
                                 v-bind="{items: items.page, itemsLength: items.filteredList.length, setPage, loadScript}"></store-list-page>
              </b-tab>
              <b-tab title="Private">
                <div class="text-center" v-if="busy.list">
                  <b-spinner></b-spinner>
                </div>
                <store-list-page v-else ref="storeListPagePrivate" :pageLimit.sync="pageLimit" :currentPage.sync="currentPagePrivate"
                                 v-bind="{items: items.pagePrivate, itemsLength: items.filteredListPrivate.length, setPage: setPagePrivate, loadScript}"></store-list-page>
              </b-tab>
            </b-tabs>
          </template>
          <template v-else>
            <div class="text-center" v-if="busy.list">
              <b-spinner></b-spinner>
            </div>
            <store-list-page v-else ref="storeListPage" :pageLimit.sync="pageLimit" :currentPage.sync="currentPage"
                             v-bind="{items: items.page, itemsLength: items.filteredList.length, setPage, loadScript}"></store-list-page>
          </template>
        </template>

        <hr/>
      </b-collapse>


      <div class="clearfix">
        <div class="pull-left">
          <b-btn v-if="$R('DEV')" variant="primary" class="mr-1" @click="$emit('newScript')">새 스크립트</b-btn>
          <b-btn variant="warning" class="mr-1" @click="reset">초기화</b-btn>
          <b-btn variant="outline-success" class="mr-1" v-b-toggle.collapseList>List Toggle</b-btn>
        </div>
        <div class="pull-right">
          <a class="btn btn-primary mr-1" href="/#/data/storeLibs" target="_blank">Libs</a>
          <b-btn variant="info" class="mr-1" @click="$refs.importModal.showModal();">데이터 Import</b-btn>
          <b-btn :variant="collapse.favorite ? 'warning' : 'outline-warning'" v-b-toggle.favorite>즐겨찾기</b-btn>
        </div>
      </div>
    </b-card>

    <b-collapse id="favorite" v-model="collapse.favorite">
      <b-card>
        <span v-for="f in Object.values(favorite)" class="btn btn-sm alert-warning mr-1 mb-1 pointer" @click="loadScript(f.no)">{{f.no}}. {{f.name}}</span>
        <div v-if="Object.values(favorite).length === 0" class="">즐겨찾기된 스크립트가 없습니다</div>
      </b-card>
    </b-collapse>

    <import-modal ref="importModal"></import-modal>
  </div>
</template>

<style scoped>
</style>

<script>
import ImportModal from '@/views/data/ImportModal.vue'
import StoreListPage from '@/views/data/StoreListPage.vue'

import StoreMixin from '@/views/data/StoreMixin'

export default {
  name: "StoreList",
  mixins: [
    StoreMixin
  ],
  components: {
    ImportModal, StoreListPage
  },
  props: ['mode', 'options', 'items', 'loadScript', 'favorite'],

  data() {
    return {
      form: {
        list: {
          search: '',
          searchSrc: false,
          hasParams: false,
          hasCharts: false,
          hasMenus: false,
          hasTags: false,
          hasCron: false,
          hasPreScript: false,
          hasAlert: 'ALL',
          hasDesc: 'ALL'
        }
      },
      busy: {list: false},
      modal: {import: false},
      collapse: {list: true, favorite: true},
      noMap: {},
      tagCntMap: {},
      currentPage: 1,
      currentPagePrivate: 1,
      pageLimit: 10,
    }
  },
  created() {
    this.$utils.getStatus(this.$options.name, this, 'collapse,pageLimit');
  },
  watch: {
    collapse: function() {
      this.$utils.setStatus(this.$options.name, this, 'collapse');
      console.log(this.collapse)
    },
    pageLimit: function () {
      this.$utils.setStatus(this.$options.name, this, 'pageLimit');
    },
    form: {
      deep: true,
      handler() {
        this.filterList();
      },
    },
  },
  methods: {
    async list() {
      this.loadTags();
      this.loadMenus();
      this.busy.list = true;
      // const j = await this.$api.getJson(`/data/store/list?search=${encodeURIComponent(this.form.list.search)}`);
      const j = await this.$api.getJson(`/data/store/list`);
      this.busy.list = false;
      if (j) {
        this.items.list = j.list.filter(e => {
          if (!e.noAuthHide || this.$R('ADMIN')) return true;
          // 권한체크
          if (e.roles.length && !this.$R(e.roles)) {
            return false;
          }
          // 사용자 체크
          if (e.users.length && !e.users.map(e => e.value).includes(this.$S.user.id)) {
            return false;
          }
          return true;
        });
        this.items.list.forEach(this.assignListData);
        this.noMap = this.$utils.arr2map(this.items.list, 'no');
        this.tagCntMap = this.$utils.arr2multi(this.items.list.map(e => e.tags).flat());
        this.options.tags.sort((a, b) => this.tagCntMap[b] - this.tagCntMap[a]);

        this.filterList();
        this.setPage(1);
      }
      const fav = await this.$api.getJson('/data/store/favorite');
      if (fav && fav.doc) {
        const favorite = this.$utils.arr2map(fav.doc.no);
        j.list.forEach(e => {
          if (favorite[e.no]) favorite[e.no] = e;
        });
        this.$emit('update:favorite', favorite);
      }
    },

    async loadTags() {
      const j = await this.$api.getJson(`/data/store/loadTags`);
      if (j) {
        this.options.tags = j.tags;
      }
    },
    async loadMenus() {
      const j = await this.$api.getJson(`/data/store/loadMenus`);
      if (j) {
        this.options.menus = j.menus;
      }
    },
    filterList() {
      const form = this.form.list;
      const s = form.search.trim();
      let list;
      const re = new RegExp(s.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i'); // escape regexp
      list = this.items.list.filter(e => {
        return (!s || (e.no === +s || re.test(e.name) || e.tags.some(t => re.test(t)) || (e.menus || []).some(t => re.test(t)) || // name, tags, menus 검색
          this.form.list.searchSrc && (re.test(e.script) || re.test(e.preScript)) || re.test(e.alert) || re.test(e.desc))) && // src 검색시 추가확인
          (!form.hasParams || e.params && e.params.length) && // params 체크시
          (!form.hasCharts || e.charts && e.charts.length) && // charts 체크시
          (!form.hasMenus || e.menus && e.menus.length) && // menus 체크시
          (!form.hasTags || e.tags && e.tags.length) && // tags 체크시
          (!form.hasCron || e.cron) && // cron 체크시
          (!form.hasPreScript || (e.preScript && e.preScript.trim())) && // 프리스크립트 체크시
          (form.hasAlert === 'ALL' || (e.alert || '').trim() && form.hasAlert === 'y' || !(e.alert || '').trim() && form.hasAlert === 'n') && // 설명 유무
          (form.hasDesc === 'ALL' || (e.desc || '').trim() && form.hasDesc === 'y' || !(e.desc || '').trim() && form.hasDesc === 'n') // 비고 유무
          ;
      });
      this.items.filteredList = list.filter(e => !e.noAuthHide);
      this.items.filteredListPrivate = list.filter(e => e.noAuthHide);
      this.setPage(this.currentPage);
      this.setPagePrivate(this.currentPagePrivate);
    },
    setPage(n) {
      this.currentPage = n;
      this.items.page = this.items.filteredList.slice((n - 1) * this.pageLimit, n * this.pageLimit);
    },
    setPagePrivate(n) {
      this.currentPagePrivate = n;
      this.items.pagePrivate = this.items.filteredListPrivate.slice((n - 1) * this.pageLimit, n * this.pageLimit);
    },
    reset() {
      this.form.list.search = '';
      this.$emit('reset');
      this.list();
    },
  }
}
</script>
