<template>
  <div>
    <div class="grid">
      <div class="th text-center">#</div>
      <div class="th">쿼리명</div>
      <div class="th">Menu</div>
      <div class="th">Tags</div>
      <div class="th text-center">Params / Charts</div>
      <div class="th text-center">CRON</div>
      <div class="th">실행권한</div>
      <div class="th text-center">실행횟수</div>
      <div class="th text-center">마지막 실행</div>
      <template v-for="q in items">
        <div class="td text-center">{{ q.no }}</div>
        <div class="td pointer bold" @click="loadScript(q.no)">{{ q.name }}</div>
        <div class="td">
          <span class="badge alert-info">{{ q.menus.join(' > ') }}</span>
        </div>
        <div class="td">
          <b-badge variant="light" v-for="t in q.tags">{{ t }}</b-badge>
        </div>
        <div class="td text-center">
          <template v-if="q.params.length || q.charts.length">
            {{ q.params.length ? `${q.params.length}` : '-' }} / {{ q.charts.length ? `${q.charts.length}` : '-' }}
          </template>
        </div>
        <div class="td text-center">{{ q.cron || '' }}</div>
        <div class="td">
          <b-badge class="mr-1" :variant="q.noAuthHide ? 'secondary' : 'light'" v-for="t in q.roles.concat(q.users.map(e => e.name)).slice(0, 3)">{{ t }}</b-badge>
          <small v-if="q.roles.concat(q.users.map(e => e.name)).length > 3">+{{q.roles.concat(q.users.map(e => e.name)).length - 3}}</small>
        </div>
        <div class="td text-center">
          <span v-if="$R('DEV')" :class="q._lastElapsed > 600 ? 'text-danger' : q._lastElapsed > 60 ? 'text-warning' : ''"
                v-b-tooltip="`마지막 실행 시간: ${q._lastElapsed} s. 마지막 실행시간이 1분 이상이면 warning, 5분 이상이면 danger 로 표시됩니다(DEV Role only).`">
            {{ q.runCount || 0 }}
          </span>
          <span v-else>{{ q.runCount || 0 }}</span>
        </div>
        <div class="td text-center">
          <span v-if="q._lastElapsedDt" :title="q._lastElapsedDt">{{q._lastElapsedDt.slice(2, 16)}}</span>
          <span v-else>
            -
          </span>
        </div>
      </template>
    </div>
    <div class="clearfix">
      <b-form class="pull-right" inline>
        <b-form-select :options="[10,15,20,30,40,50,100]" v-model="_pageLimit" @change="setPage(_currentPage)"></b-form-select>
      </b-form>
      <b-pagination :total-rows="itemsLength" :per-page="_pageLimit" :limit="20" v-model="_currentPage" prev-text="Prev" next-text="Next" @change="setPage"/>
    </div>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 40px 3fr 120px repeat(1, 1fr) 110px 140px 185px 70px 110px;
  margin: 10px 0;
}
.grid .th {
  padding: 5px;
  background-color: #eee;
}
.grid .td {
  padding: 5px;
}
</style>

<script>

export default {
  name: "StoreListPage",
  props: ['items', 'itemsLength', 'currentPage', 'pageLimit', 'setPage', 'loadScript'],
  data() {
    return {
    }
  },
  computed: {
    _pageLimit: {
      get() {
        return this.pageLimit;
      },
      set(v) {
        this.$emit('update:pageLimit', v);
      }
    },
    _currentPage: {
      get() {
        return this.currentPage;
      },
      set(v) {
        this.$emit('update:currentPage', v);
      }
    },
  },
  methods: {
  }
}
</script>
