const snippet = [
  {
    title: 'blu.arr2map',
    list: [
      {name: 'confirmed', data: `
// const cfs = await sdb.hub.confirmed.find({goods_no: 40000000}, {projection: {goods_no: 1}}).toArray();
const cfs = await sdb.hub.confirmed.select('goods_no', {goods_no: 40000000});
const cfMap = blu.arr2map(cfs, 'goods_no');`},
      {name: 'mapped', data: `
// const mps = await second.goods.mapped.find({goods_no: 40000000}, {projection: {goods_no: 1}}).toArray();
const mps = await second.goods.mapped.select('goods_no', {goods_no: 40000000});
const mpMap = blu.arr2map(mps, 'goods_no');`},
      {name: 'deliver_boards', data: `
// const delis = await sdb.balis.deliver_boards.find({goods_no: 40000000}, {projection: {goods_no: 1}}).toArray();
const delis = await sdb.balis.deliver_boards.select('goods_no', {goods_no: 40000000});
const deliMap = blu.arr2map(delis, 'goods_no');`},
      {name: 'gm', data: `
// const gms = await sdb.hub.gm.find({id: 'GM0000001'}, {projection: {id: 1}}).toArray();
const gms = await sdb.hub.gm.select('id', {id: 'GM0000001'});
const gmMap = blu.arr2map(gms, 'id');`},
      {name: 'meta_shops', data: `
// const shops = await sdb.hub.meta_shops.find({shop_id: 0}, {projection: {shop_id: 1}}).toArray();
const shops = await sdb.hub.meta_shops.select('shop_id', {shop_id: 0});
const shopMap = blu.arr2map(shops, 'shop_id');`}
    ]
  },
  {
    title: 'blu.arr2multi',
    list: [
      {name: 'confirmed', data: `
// const cfs = await sdb.hub.confirmed.find({goods_no: 40000000}, {projection: {shop_id: 1}}).toArray();
const cfs = await sdb.hub.confirmed.select('shop_id', {goods_no: 40000000});
const cfMul = blu.arr2multi(cfs, 'shop_id');`},
      {name: 'loop multi', data: `
Object.entries(multi).forEach(([key, arr]) => {
});
for (const [key, arr] of Object.entries(multi)) {
}`}
    ]
  },
  {
    title: 'chunked loop',
    list: [
      {name: 'lastId', data: `
const limit = 10000;
let lastId = true;
while (lastId) {
  const cfs = await sdb.hub.confirmed.select('_id', {...(lastId !== true ? {_id: {$lt: lastId}} : {}), some: null}, {sort: {_id: -1}, limit});
  const firstId = cfs.length ? cfs[0]._id : false;
  lastId = cfs.length ? cfs.slice(-1)[0]._id : false;
  console.log(\`cf \${cfs[0]?.goods_no} ~ \${cfs.slice(-1)[0]?.goods_no} done\`);
}`},
      {name: 'lastNo(abs)', data: `
const limit = 1000000;
let lastNo = 45000000;
while (lastNo) {
  const docs = await sdb.hub.confirmed.find({goods_no: {$lt: lastNo, $gte: lastNo - limit}, some: null}, {projection: {goods_no: 1}}).sort({goods_no: -1}).toArray();
  console.log('docs.length', docs.length, 'lastNo', lastNo);
  lastNo -= limit;
  if (lastNo <= 0) break;
}`},
      {name: 'lastNo(rel)', data: `
const limit = 10000;
let lastNo = true;
while (lastNo) {
  const rows = await sdb.hub.confirmed.select('goods_no', lastNo !== true ? {goods_no: {$lt: lastNo}, some: null} : {some: null},
    {sort: {goods_no: -1}, limit});
  if (rows.length === 0) break;
  console.log(\`cf \${lastNo} ~ \${rows.slice(-1)[0].goods_no}, \${rows.length} goods\`);
  lastNo = rows.slice(-1)[0].goods_no;
}`}
    ]
  },
  {
    title: 'libs',
    list: [
      {name: 'esSelect', data: `
const goods = await esSelect({
  select: 'goods_no',
  from: 'hub.confirmed',
  where: \`b_rank = 1 and b_rank_class = '1B'\`,
  limit: 500000,
});
      `},
      {name: 'esQuery - distinct count', data: `
const count_live_gm = (await esQuery('hub.confirmed', {
  "must": [
    {"term": {"goods_status": "registered"}},
    {"term": {"stock_status": "normal"}},
    {"term": {"display_status": "view"}},
    {"term": {"gm_status": "confirmed"}},
  ]
}, {
  returnSource: false,
  body: {
    "size": 0,
    "aggs": {
      "distinct_count": {
        "cardinality": {"field": "gm_id"}
      }
    }
  }
})).aggregations.distinct_count.value;
      `},
      {name: 'esQuery - buckets', data: `
const buckets = (await esQuery('hub.orders', {
  "must": [
    {"range": {"order_date": {"gte": "2023-01-01", "lte": "2023-12-31"}}},
  ]
}, {
  returnSource: false,
  body: {
    "size": 0,
    "aggs": {
      "orders": {
        "terms": {
          "field": "brand_no",
          "size": 20
        },
        "aggs": {
          "avg_sales": {
            "avg": {
              "field": "sales_price"
            }
          },
          "sum_sales": {
            "sum": {
              "field": "sales_price"
            }
          },
          "sum_qty": {
            "sum": {
              "field": "qty"
            }
          }
        }
      }
    }
  }
})).aggregations.orders.buckets;
      `},
    ]
  },
  {
    title: 'ETC',
    list: [
      {name: 'Q.createJob', data: `
const Q = require('./queue');
await Q.removeMapped.createJob({query: {matched_brandno: brand_no + '', goods_no: {$exists: 0}}, signature}).save();
      `},
      {name: 'publish', data: `
blu.redis._pub.publish('meta:brand', JSON.stringify({type: 'remove', brand_no}));
      `},
    ]
  },
];

module.exports = snippet;
