<template>
  <b-modal title="Data 를 DB 로 Import" v-model="modal" size="xl" ok-only ok-title="닫기">
    <b-input-group class="mb-3">
      <b-input-group-prepend>
        <b-button variant="primary" @click="list" :disabled="busy.list">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input type="text" v-model="search" @keypress.enter="list" v-focus></b-form-input>
    </b-input-group>
    <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage.list" :isBusy="busy.list" :getMoreBusy="busy.listmore"
             :hasMore="hasMore.list" :caption="items.list.length + ' 개 데이터'" @btn-clicked="btnAction" @get-more="list(true)"></c-table>
    <b-btn variant="danger" class="mt-n3" @click="removeData" :disabled="busy.removeData">
      선택 삭제<b-spinner class="ml-1" small v-if="busy.removeData"></b-spinner>
    </b-btn>

    <hr/>

    <b-alert variant="success" show>
      데이터는 db.data.import 에 {id, type, data} 형태로 저장됩니다.<br/>
      xlsx:<br/>
      const {data: [{rows}]} = await db.data.import.findOne({id: 'id'});<br/>
      text:<br/>
      const {data: lines} = await db.data.import.findOne({id: 'id'});<br/>
      json:<br/>
      const {data: j} = await db.data.import.findOne({id: 'id'});<br/>
    </b-alert>
    <div class="mb-2">
      <span>ID</span><br/>
      <b-form-input v-model="id" ref="id"></b-form-input>
    </div>
    <b-tabs v-model="tabIndex">
      <b-tab title="File">
        <small>* xlsx, csv, tsv, json, txt 를 업로드할 수 있습니다.</small>
        <div class="drop" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover"
             @click="_=>{$refs.fileInput.value = null;$refs.fileInput.click()}">
          Drop File or Click
        </div>
        <input type="file" ref="fileInput" style="display: none" @change="handleFile">
        <small>{{ fileDesc }}</small>
      </b-tab>
      <b-tab title="JSON">
        <b-textarea v-model="data" rows="10"></b-textarea>
      </b-tab>
      <b-tab title="Text">
        <b-textarea v-model="text" rows="10"></b-textarea>
        <b-form-checkbox v-model="trim">각 line 별 좌우 공백을 제거합니다</b-form-checkbox>
        <b-form-checkbox v-model="filterEmpty">line 이 비어있다면 제외합니다</b-form-checkbox>
      </b-tab>
    </b-tabs>
    <b-btn variant="success" class="mt-2" @click="saveData" :disabled="busy.saveData">저장<b-spinner class="ml-1" small v-if="busy.saveData"></b-spinner></b-btn>
    <div>
    </div>
  </b-modal>
</template>

<script>
import {readXlsx} from '@/shared/impexp'
import {parse} from 'csv-parse';

export default {
  name: 'ImportModal',
  data() {
    return {
      id: '',
      data: '',
      text: '',
      trim: true,
      filterEmpty: true,
      modal: false,
      file: null,
      fileDesc: '',
      fileData: '',
      fileName: '',
      tabIndex: 0,

      search: '',
      skip: 0,
      limit: 15,
      fields: {
        list: [
          'selected',
          {key: 'id', label: 'ID'},
          {key: 'type', label: '타입'},
          {key: 'fileDesc', label: '파일명세'},
          {key: '_dt', label: '일시', class: 'text-center'},
          {key: '_name', label: '작성자', class: 'text-center'},
          {key: '_actions', label: '기능', style: {width: '80px', textAlign: 'center'}, buttons: [{label: '미리보기', event: 'showDetail'}]},
        ]
      },
      perPage: {list: 10},
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false, removeData: false, saveData: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
    };
  },
  methods: {
    reset() {
      this.id = '';
      this.data = '';
      this.text = '';
      this.file = null;
      this.fileDesc = '';
      this.fileData = '';
      this.fileName = '';
      this.tabIndex = 0;
    },
    showModal() {
      this.reset();
      this.search = '';
      this.modal = true;
      this.list();
    },
    async list(more) {
      await this.$api.postTable(this, '/data/store/importList', {search: this.search, skip: this.skip, limit: this.limit}, {more});
    },
    btnAction(row, event) {
      if (event === 'showDetail') {
        this.showDetail(row);
      }
    },
    async showDetail(row) {
      const j = await this.$api.getJson(`/data/store/importData?id=${encodeURIComponent(row.item.id)}`);
      if (j) {
        this.$modal.show({title: 'Imported Data', type: 'pre', text: j.lines.join('\n')});
      }
    },
    async removeData() {
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('삭제할 데이터를 선택해주세요');
      if (!confirm(`${selected.length} 개 데이터를 삭제하시겠습니까?`)) return;

      this.busy.removeData = true;
      await this.$api.postJson(`/data/store/removeData`, {ids: selected.map(e => e.id)});
      this.busy.removeData = false;
      this.list().then();
    },
    async saveData() {
      if (!this.id) {
        this.$refs.id.focus();
        return alert('id 를 입력해주세요');
      }
      let data, type;
      if (this.tabIndex === 0) {
        data = this.fileData;
        type = 'file';
      } else if (this.tabIndex === 1) {
        try {
          data = JSON.parse(this.data);
          type = 'json';
        } catch (e) {
          return alert('JSON 형식에 오류가 있습니다');
        }
      } else if (this.tabIndex === 2) {
        data = this.text.split(/\r?\n/).map(e => {
          let line = e;
          if (this.trim) line = line.trim();
          return line;
        }).filter(e => !this.filterEmpty || e !== '');
        type = 'text';
      }
      const j = await this.$api.postJson('/data/store/importData', {id: this.id, data, type, fileDesc: this.fileDesc});
      if (j) {
        this.$alertTop('저장되었습니다');
        this.reset();
        this.list().then();
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      /*
        console.log(file.type);
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        text/plain
        application/vnd.ms-excel
        text/x-sh
        application/json
       */
      this.file = file;
      this.fileName = file.name;
      let data;

      if (file.name.match(/\.xlsx?$/)) {
        const {sheets} = await readXlsx(file, {multi: true});
        this.fileDesc = `${file.name}: ${sheets.length} sheets, ${sheets.map(e => e.rows.length).join(', ')} rows`
        data = sheets;
      } else if (file.name.match(/\.csv$/)) {
        const f = await this.readFile(file, {charset: 'euc-kr'});
        data = await new Promise(resolve => {
          parse(f, {columns: true}, (err, res) => resolve(res));
        });
        this.fileDesc = `${file.name}: ${data.length} rows`;
      } else if (file.name.match(/\.tsv$/)) {
        const f = (await this.readFile(file)).split(/\r?\n/);
        data = await new Promise(resolve => {
          parse(f, {delimiter: '\t', columns: true}, (err, res) => resolve(res));
        });
        this.fileDesc = `${file.name}: ${data.length} rows`;
      } else if (file.name.match(/\.json$/)) {
        const j = JSON.parse(await this.readFile(file));
        const type = this.$utils.typeOf(j);
        this.fileDesc = `${file.name}: ${type}${type === 'array' ? `, ${j.length} lines` : ''}`;
        data = j;
      } else if (file.name.match(/\.txt$/)) {
        const t = (await this.readFile(file)).split(/\r?\n/);
        this.fileDesc = `${file.name}: ${t.length} lines`;
        data = t;
      }

      this.fileData = data;
    },
    async readFile(file, {charset = 'utf-8'} = {}) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          try {
            resolve(new TextDecoder(charset).decode(new Uint8Array(e.target.result)));
          } catch (e) {
            reject(e);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    }
  },
}
</script>

<style scoped>
.drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 50px;
  text-align: center;
  font: 20pt bold, "Vollkorn";
  color: #bbb;
  cursor: pointer;
}
</style>
